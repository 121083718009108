import React, { useState, useEffect } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Button,
  IconButton,
  TextField,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Snackbar,
  Alert,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import BlockIcon from "@mui/icons-material/Block";
import { COLORS } from "styles/colors";
import { useMediaQuery } from "react-responsive";

import store from "store";
import ReSigninDialog from "components/AppBar/Auth/AuthOption/ReSigninDialog";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    icon: { main: COLORS.cornflowerblue },
    button: { main: COLORS.black },
    textfield: { main: COLORS.legoyellow },
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "500px",
  },
  mobilePaper: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
});

const AuthOptionDrawer = (props) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);
  const [showNameChange, setShowNameChange] = useState(false);
  const [showEmailChange, setShowEmailChange] = useState(false);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [showDeleteAuth, setShowDeleteAuth] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [showReSigninDialog, setShowReSigninDialog] = useState(false);
  const [nextStep, setNextStep] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNewPassword, setUserNewPassword] = useState("");
  const [userNewPasswordConfirm, setUserNewPasswordConfirm] = useState("");

  useEffect(() => {
    store.subscribe(() => {
      setUserObj(store.getState().userObj);
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const goNextStep = () => {
    switch (nextStep) {
      case "ShowPasswordChange":
        setNextStep("");
        setShowPasswordChange(true);
        break;
      case "ShowDeleteAuth":
        setNextStep("");
        setShowDeleteAuth(true);
        break;
      default:
        break;
    }
  };

  const getUserSettingsArea = () => {
    if (userObj === null) {
      return <div />;
    } else {
      if (showNameChange) {
        return (
          <div>
            <TextField
              label="이름"
              fullWidth
              color="textfield"
              defaultValue={store.getState().userObj.displayName}
              onChange={(event) => {
                setUserName(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            <Button
              onClick={() => {
                props.updateUserProfile("displayName", userName);
                setShowNameChange(false);
              }}
              color="button"
            >
              확인
            </Button>
            <Button
              onClick={() => {
                setShowNameChange(false);
              }}
              color="button"
            >
              취소
            </Button>
          </div>
        );
      } else if (showEmailChange) {
        return (
          <div>
            <TextField
              label="이메일"
              fullWidth
              color="textfield"
              defaultValue={store.getState().userObj.email}
              onChange={(event) => {
                setUserEmail(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            <Button
              onClick={() => {
                props.updateUserProfile("email", userEmail);
                setShowEmailChange(false);
              }}
              color="button"
            >
              확인
            </Button>
            <Button
              onClick={() => {
                setShowEmailChange(false);
              }}
              color="button"
            >
              취소
            </Button>
          </div>
        );
      } else if (showPasswordChange) {
        return (
          <div>
            <TextField
              label="새 비밀번호 입력"
              fullWidth
              type="password"
              color="textfield"
              onChange={(event) => {
                setUserNewPassword(event.target.value);
              }}
              error={userNewPassword.length < 6 ? true : false}
              helperText={
                userNewPassword.length < 6
                  ? "비밀번호는 6자 이상이어야 합니다"
                  : ""
              }
              style={{ marginBottom: "10px" }}
            />
            <TextField
              label="새 비밀번호 재입력"
              fullWidth
              type="password"
              color="textfield"
              onChange={(event) => {
                setUserNewPasswordConfirm(event.target.value);
              }}
              error={
                userNewPasswordConfirm !== "" &&
                userNewPassword !== userNewPasswordConfirm
                  ? true
                  : false
              }
              helperText={
                userNewPasswordConfirm !== "" &&
                userNewPassword !== userNewPasswordConfirm
                  ? "비밀번호 불일치"
                  : ""
              }
              style={{ marginBottom: "10px" }}
            />
            <Button
              color="button"
              onClick={() => {
                if (
                  userNewPassword.length >= 6 &&
                  userNewPassword === userNewPasswordConfirm
                ) {
                  props.updateUserProfile("password", userNewPassword);
                  setShowPasswordChange(false);
                } else {
                  setShowErrorSnackbar(true);
                }
              }}
            >
              확인
            </Button>
            <Button
              onClick={() => {
                setShowPasswordChange(false);
              }}
              color="button"
            >
              취소
            </Button>
          </div>
        );
      } else if (showDeleteAuth) {
        return (
          <Container style={{ marginTop: "25px" }}>
            <Typography style={{ margin: "10px" }} variant="subtitle2">
              {store.getState().userObj.displayName} 님의
            </Typography>
            <Typography style={{ margin: "10px" }} variant="subtitle2">
              계정 및 모든 회원 정보가 삭제됩니다.
            </Typography>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  props.updateUserProfile("delete", "");
                  setShowDeleteAuth(false);
                  props.closeDrawer();
                }}
                variant="outlined"
                size="small"
                color="button"
                style={{ margin: "5px" }}
              >
                확인
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteAuth(false);
                  props.closeDrawer();
                }}
                variant="outlined"
                size="small"
                color="button"
                style={{ margin: "5px" }}
              >
                취소
              </Button>
            </div>
          </Container>
        );
      } else {
        return (
          <List>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowNameChange(true);
                }}
              >
                <ListItemIcon>
                  <AssignmentIndIcon color="icon" />
                </ListItemIcon>
                이름 변경
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowEmailChange(true);
                }}
              >
                <ListItemIcon>
                  <MarkEmailReadIcon color="icon" />
                </ListItemIcon>
                이메일 변경
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowReSigninDialog(true);
                  setNextStep("ShowPasswordChange");
                }}
              >
                <ListItemIcon>
                  <LockIcon color="icon" />
                </ListItemIcon>
                비밀번호 변경
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  props.signOut();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon color="icon" />
                </ListItemIcon>
                로그아웃
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowReSigninDialog(true);
                  setNextStep("ShowDeleteAuth");
                }}
              >
                <ListItemIcon>
                  <BlockIcon color="icon" />
                </ListItemIcon>
                계정 탈퇴
              </ListItemButton>
            </ListItem>
          </List>
        );
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onOpen={() => {}}
        onClose={props.onClose}
        classes={
          isMobile ? { paper: classes.mobilePaper } : { paper: classes.pcPaper }
        }
      >
        <Container className={classes.container}>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                      setShowNameChange(false);
                      setShowEmailChange(false);
                      setShowPasswordChange(false);
                    }}
                    edge="start"
                    color="inherit"
                    aria-label="backtohome"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    color="textPrimary"
                  >
                    계정 설정
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {getUserSettingsArea()}
        </Container>
        <Snackbar
          open={showErrorSnackbar}
          autoHideDuration={3000}
          onClose={() => {
            setShowErrorSnackbar(false);
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setShowErrorSnackbar(false);
            }}
            severity="error"
          >
            입력 값을 다시 확인하세요 🙄
          </Alert>
        </Snackbar>
      </SwipeableDrawer>
      <ReSigninDialog
        open={showReSigninDialog}
        onClose={() => {
          setShowReSigninDialog(false);
        }}
        goNextStep={goNextStep}
      />
    </ThemeProvider>
  );
};

export default withStyles(styles)(AuthOptionDrawer);
