import React, { useState, useEffect } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Backdrop,
  SwipeableDrawer,
  List,
  ListItem,
  CircularProgress,
  Divider,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { COLORS } from "styles/colors";
import { useMediaQuery } from "react-responsive";

import ProductListItem from "components/ProductListItem";
import store from "store";

require("dotenv").config();

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    button: { main: COLORS.black },
    textfield: { main: COLORS.legoyellow },
    location: { main: COLORS.cornflowerblue },
    locationadd: { main: COLORS.forestgreen },
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "500px",
  },
  mobilePaper: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
});

const FavoriteProductDrawer = (props) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);
  const [favoriteProduct, setFavoriteProduct] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      setUserObj(store.getState().userObj);
      setFavoriteProduct(store.getState().favoriteProduct);
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const getMyFavoriteProductArea = () => {
    if (userObj === null) {
      return (
        <div>
          <List>
            <ListItem>
              로그인하시고 관심 상품 모아보세요!
            </ListItem>
          </List>
          <Divider />
        </div>
      );
    } else {
      return (
        <div>
          <List>
            {favoriteProduct !== "" ? (
              favoriteProduct && favoriteProduct.map((e, index) => {
                return (
                  <ProductListItem
                    key={index}
                    productInfo={e.productInfo}
                    onClose={props.closeAll}
                    showBackdrop={(show)=>{setShowBackdrop(show)}}
                  />
                );
              })
            ) : (
              <div />
            )}
          </List>
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onOpen={() => {}}
        onClose={props.onClose}
        classes={
          isMobile ? { paper: classes.mobilePaper } : { paper: classes.pcPaper }
        }
      >
        <Container className={classes.container}>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                    edge="start"
                    color="inherit"
                    aria-label="backtohome"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textPrimary"
                  >
                    나의 관심 상품
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {getMyFavoriteProductArea()}
        </Container>
      </SwipeableDrawer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default withStyles(styles)(FavoriteProductDrawer);
