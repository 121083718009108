import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import * as DBManage from "functions/DBManage";
import SearchView from "components/SearchView";
import AuthDrawer from "components/AppBar/Auth/AuthDrawer";
import AuthOptionDrawer from "components/AppBar/Auth/AuthOption/AuthOptionDrawer";
import FavoriteProductDrawer from "components/AppBar/Auth/FavoriteProduct/FavoriteProductDrawer";
import FavoritePlaceDrawer from "components/AppBar/Auth/FavoritePlace/FavoritePlaceDrawer";
import SigninDialog from "components/AppBar/Auth/SigninDialog";
import RecommendDrawer from "components/AppBar/Recommend/RecommendDrawer";
import { COLORS } from "styles/colors";
import { authService } from "fbase";
import store from "store";

const theme = createTheme({
  palette: {
    primary: {
      light: "#fddf5d",
      main: COLORS.legoyellow,
      dark: "#b19725",
    },
    secondary: {
      light: "#ff7961",
      main: COLORS.black,
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  toolbar: {
    height: "60px",
  },
  title: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginLeft: 0,
    width: "100%",
    direction: "row",
    alignItems: "center",
  },
  inputRoot: {
    width: "calc(100% - 140px)",
    color: "inherit",
    position: "absolute",
    left: "20px",
    right: "10px",
  },
  inputInput: {
    alignItems: "center",
    justifyContent: "center",
  },
  tab: {
    height: "42px",
    minWidth: 1,
  },
});

const TopAppBar = forwardRef((props, ref) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);
  const [category, setCategory] = useState(0);
  const [showSearchView, setShowSearchView] = useState(false);
  const [showRecommendDrawer, setShowRecommendDrawer] = useState(false);
  const [showAccountDrawer, setShowAccountDrawer] = useState(false);
  const [showAuthOptionDrawer, setShowAuthOptionDrawer] = useState(false);
  const [showFavoriteProductDrawer, setShowFavoriteProductDrawer] = useState(false);
  const [showFavoritePlaceDrawer, setShowFavoritePlaceDrawer] = useState(false);
  const [showSigninDialog, setShowSigninDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  useImperativeHandle(ref, () => ({
    backHandler(){
      if(showSearchView){
        setShowSearchView(false);
      } else if(showRecommendDrawer){
        setShowRecommendDrawer(false);
      } else if(showFavoriteProductDrawer){
        setShowFavoriteProductDrawer(false);
      } else if(showFavoritePlaceDrawer){
        setShowFavoritePlaceDrawer(false);
      } else if(showSigninDialog){
        setShowSigninDialog(false);
      } else if(showAuthOptionDrawer){
        setShowAuthOptionDrawer(false);
      } else if(showAccountDrawer){
        setShowAccountDrawer(false);
      } else{
      }
    }
  }));

  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      store.dispatch({ type: "SETUSER", userObj: user });
      if (user !== null) {
        DBManage.getUserFavoritePlace(user.uid);
        DBManage.getUserFavoriteProduct(user.uid);
      }
    });
    store.subscribe(async () => {
      setUserObj(store.getState().userObj);
      switch (store.getState().category) {
        case "FESTIVAL_AND_THEME":
          setCategory(0);
          break;
        case "LEISURE_AND_SPORTS":
          setCategory(1);
          break;
        case "KID_AND_EXPERIENCE":
          setCategory(2);
        break;
        case "PERFORMANCE_AND_EXHIBITION":
          setCategory(3);
          break;
        case "THEME_PARK":
          setCategory(4);
          break;
        case "WATER_PARK":
          setCategory(5);
          break;
        case "SIGHTSEEING_PASS":
          setCategory(6);
          break;
        case "GOLF":
          setCategory(7);
          break;
        case "SKI":
          setCategory(8);
          break;
        default:
          break;
      }
    });
  }, []);

  const updateUserProfile = (key, value) => {
    switch (key) {
      case "displayName":
        userObj.updateProfile({ displayName: value });
        break;
      case "email":
        userObj.updateEmail(value);
        break;
      case "password":
        userObj.updatePassword(value);
        break;
      case "delete":
        userObj.delete();
        break;
      default:
        break;
    }
  };

  const signOut = () => {
    authService.signOut();
    store.dispatch({ type: "CLEARUSER" });
  };

  const searchDB = async (keyword) => {
    setShowBackdrop(true);
    await DBManage.searchDB(keyword);
    setShowSearchView(true);
    setShowBackdrop(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="primary">
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setShowSearchView(false);
              setShowRecommendDrawer(true);
            }}
            style={{ marginLeft: "0" }}
          >
            <img
              src="https://storage.googleapis.com/venti-99dcc.appspot.com/ventilogo.png"
              style={{ height: "20px", width: "35px" }}
              alt=""
            />
          </IconButton>
          <InputBase
            placeholder="상품명, 지역명으로 검색"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            value={searchKeyword}
            onChange={(event) => {
              setSearchKeyword(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchDB(searchKeyword);
              }
            }}
            endAdornment={
              searchKeyword !== "" ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setSearchKeyword("");
                    }}
                  >
                    <CancelRoundedIcon fontSize="small" color="action" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <div />
              )
            }
            inputProps={{ "aria-label": "search" }}
            autoComplete="true"
          />
          <IconButton
            onClick={() => {
              searchDB(searchKeyword);
            }}
            color="inherit"
            style={{ marginLeft: "20px", marginRight: "5px" }}
          >
            <SearchIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setShowAccountDrawer(true);
            }}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
        <Tabs
          id="navtabs"
          className={classes.tab}
          value={category}
          onChange={(event, value) => {
            setCategory(value);
          }}
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab
            label="체험/클래스"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "FESTIVAL_AND_THEME" });
            }}
          />
          <Tab
            label="액티비티"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "LEISURE_AND_SPORTS" });
            }}
          />
          <Tab
            label="키즈"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "KID_AND_EXPERIENCE" });
            }}
          />
          <Tab
            label="공연전시"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "PERFORMANCE_AND_EXHIBITION" });
            }}
          />
          <Tab
            label="테마파크"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "THEME_PARK" });
            }}
          />
          <Tab
            label="워터파크"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "WATER_PARK" });
            }}
          />
          <Tab
            label="제주여행"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "SIGHTSEEING_PASS" });
            }}
          />
          <Tab
            label="골프"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "GOLF" });
            }}
          />
          <Tab
            label="스키"
            className={classes.tab}
            onClick={async () => {
              store.dispatch({ type: "CATEGORY", category: "SKI" });
            }}
          />
        </Tabs>
      </AppBar>
      <SearchView
        open={showSearchView}
        onClose={() => {
          setShowSearchView(false);
        }}
      />
      <RecommendDrawer
        open={showRecommendDrawer}
        onClose={() => {
          setShowRecommendDrawer(false);
        }}
      />
      <AuthDrawer
        open={showAccountDrawer}
        onClose={() => {setShowAccountDrawer(false);}}
        showSigninDialog={() => {setShowSigninDialog(true);}}
        showAuthOptionDrawer={() => {setShowAuthOptionDrawer(true);}}
        showFavoriteProductDrawer={() => {setShowFavoriteProductDrawer(true);}}
        showFavoritePlaceDrawer={() => {setShowFavoritePlaceDrawer(true);}}
      />
      <AuthOptionDrawer
        open={showAuthOptionDrawer}
        onClose={() => {
          setShowAuthOptionDrawer(false);
        }}
        updateUserProfile={(key, value) => {
          updateUserProfile(key, value);
        }}
        signOut={() => {
          signOut();
          setShowAuthOptionDrawer(false);
        }}
        closeDrawer={() => {
          setShowAuthOptionDrawer(false);
        }}
      />
      <FavoriteProductDrawer
        open={showFavoriteProductDrawer}
        onClose={() => {setShowFavoriteProductDrawer(false);}}
        closeAll={() => {setShowAccountDrawer(false);setShowFavoriteProductDrawer(false);}}
      />
      <FavoritePlaceDrawer
        open={showFavoritePlaceDrawer}
        onClose={() => {setShowFavoritePlaceDrawer(false);}}
      />
      <SigninDialog
        open={showSigninDialog}
        onClose={() => {
          setShowSigninDialog(false);
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
});

export default withStyles(styles)(TopAppBar);
