import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { COLORS } from "styles/colors";

import { firebaseInstance, authService } from "fbase";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.legoyellow },
    button: { main: COLORS.black },
  },
});

const SigninDialog = (props) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignup, setIsSignup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const emailSubmit = async (type, displayName) => {
    try {
      await authService.setPersistence(
        firebaseInstance.auth.Auth.Persistence.LOCAL
      );
      if (type === "signup") {
        const newUser = await authService.createUserWithEmailAndPassword(
          email,
          password
        );
        await newUser.user.updateProfile({ displayName: displayName });
      } else if (type === "signin") {
        await authService.signInWithEmailAndPassword(email, password);
      }
      props.onClose();
    } catch (error) {
      setErrorMsg(error.message);
    }
  };
  const socialSubmit = async (platform) => {
    try {
      let provider;
      if (platform === "google") {
        provider = new firebaseInstance.auth.GoogleAuthProvider();
      }
      await authService.signInWithRedirect(provider);
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const SigninContent = () => {
    return (
      <ThemeProvider theme={theme}>
        <DialogContent>
          <Typography
            variant="subtitle2"
            style={{ margin: "10px 0px 10px 0px" }}
          >
            로그인
          </Typography>
          <Grid container direction="column">
            <TextField
              label="이메일 주소"
              fullWidth
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            <TextField
              label="비밀번호"
              type="password"
              fullWidth
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            {errorMsg}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    emailSubmit("signin");
                  }}
                  color="button"
                >
                  로그인
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={props.onClose} color="button">
                  취소
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <Button
                  onClick={() => {
                    socialSubmit("google");
                  }}
                  variant="outlined"
                  color="button"
                  startIcon={
                    <img
                      src="https://storage.googleapis.com/venti-99dcc.appspot.com/googleicon.png"
                      style={{ height: "20px", width: "20px" }}
                      alt=""
                    />
                  }
                >
                  Google로 계속하기
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              style={{ marginTop: "20px" }}
            >
              <Grid item>아직 계정이 없으신가요?</Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setIsSignup(true);
                  }}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  회원가입
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </ThemeProvider>
    );
  };
  const SignupContent = () => {
    return (
      <ThemeProvider theme={theme}>
        <DialogContent>
          <Typography
            variant="subtitle2"
            style={{ margin: "10px 0px 10px 0px" }}
          >
            회원가입
          </Typography>
          <Grid container direction="column">
            <TextField
              label="이름(닉네임)"
              fullWidth
              onChange={(event) => {
                setDisplayName(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            <TextField
              label="이메일 주소"
              fullWidth
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            <TextField
              label="비밀번호"
              type="password"
              fullWidth
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              style={{ marginBottom: "10px" }}
            />
            {errorMsg}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    emailSubmit("signup", displayName);
                  }}
                  color="button"
                >
                  회원가입
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={props.onClose} color="button">
                  취소
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ marginTop: "20px" }}
            >
              <Grid item>
                <Button
                  onClick={() => {
                    socialSubmit("google");
                  }}
                  variant="outlined"
                  color="button"
                  startIcon={
                    <img
                      src="https://storage.googleapis.com/venti-99dcc.appspot.com/googleicon.png"
                      style={{ height: "20px", width: "20px" }}
                      alt=""
                    />
                  }
                >
                  Google로 시작하기
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-evenly"
              style={{ marginTop: "20px" }}
            >
              <Grid item>로그인으로 돌아가기</Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setIsSignup(false);
                  }}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  로그인
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </ThemeProvider>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ backgroundColor: COLORS.legoyellow }}>
        <Typography variant="subtitle1" align="center">
          로그인하고 내 주변 할인 알림 받으세요 😁
        </Typography>
      </DialogTitle>
      {isSignup ? SignupContent() : SigninContent()}
    </Dialog>
  );
};

export default SigninDialog;
