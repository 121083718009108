import React, { useRef, useEffect } from "react";
import { withStyles } from "@mui/styles";

import TopAppBar from "components/AppBar/TopAppBar";
import MapView from "components/Map/MapView";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
});

const Home = (props) => {
  const { classes } = props;
  const topAppBarRef = useRef();
  const mapViewRef = useRef();

  useEffect(() => {
    addNativeListner();
  }, []);

  const nativeListner = event => {
    const nativeRes = JSON.parse(event.data);
    switch(nativeRes.type){
      case "RES_GPS":
        mapViewRef.current.setNativeLocation(nativeRes.latitude, nativeRes.longitude);
        break;
      case "REQ_BACK":
        topAppBarRef.current.backHandler();
        mapViewRef.current.backHandler();
        break;
      default:
        break;
    }
  };

  const addNativeListner = () => {
    if (window.ReactNativeWebView) {
      /** android */
      document.addEventListener("message", nativeListner);
      /** ios */
      window.addEventListener("message", nativeListner);
    }
  }

  return (
    <div className={classes.root}>
      <TopAppBar ref={topAppBarRef} />
      <MapView ref={mapViewRef} />
    </div>
  );
};

export default withStyles(styles)(Home);
