import React, { useState, useEffect } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Divider,
  IconButton,
  SwipeableDrawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { COLORS } from "styles/colors";
import { useMediaQuery } from "react-responsive";

import store from "store";
import * as DBManage from "functions/DBManage";
import ProductListItem from "components/ProductListItem";
import KakaoAdFit from "components/AppBar/Auth/KakaoAdFit";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    icon: { main: COLORS.cornflowerblue },
    button: { main: COLORS.black },
    textfield: { main: COLORS.legoyellow },
    sunny: { main: COLORS.orangered },
    cloudy: { main: COLORS.gray },
    rainy: { main: COLORS.midnightblue },
    snow: { main: COLORS.midnightblue },
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "600px",
  },
  mobilePaper: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
  ad: {
    position: "absolute",
    bottom: "5px",
    width: "90%",
    margin: "auto",
    overflow: "hidden",
  },
});

const getContentHeader = (weather, isWeatherLoading) => {
  return (
    <Box>
      {isWeatherLoading ? (
        <div style={{ textAlign: "center", marginTop: "45px" }}>
          <CircularProgress size="37px" color="inherit" />
        </div>
      ) : weather === [] ? (
        <div />
      ) : (
        <div>
          <Tabs
            variant="scrollable"
            allowScrollButtonsMobile
            textColor="primary"
          >
            {weather && weather.map((e, index) => {
              let icon;
              let labeldegree = e.min + "° / " + e.max + "°";
              let labeltime = e.date;
              icon = <img
                src={"https://openweathermap.org/img/wn/" + e.icon + "@2x.png"}
                style={{ height: "35px", width: "35px" }}
                alt=""
              />;
              
              return (
                <Tab
                  icon={icon}
                  label={
                    <React.Fragment>
                      <div
                        style={{ fontSize: "x-small", color: COLORS.darkgray }}
                      >
                        {labeldegree}
                      </div>
                      <div style={{ fontSize: "smaller" }}>{labeltime}</div>
                    </React.Fragment>
                  }
                  value={index}
                  style={{ minWidth: "10px" }}
                />
              );
            })}
          </Tabs>
        </div>
      )}
    </Box>
  );
};

const RecommendDrawer = (props) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);
  const [favoritePlace, setFavoritePlace] = useState(null);
  const [searchRangeResult, setSearchRangeResult] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState({
    address: "",
    latitude: "",
    longitude: "",
  });
  const [gpsLoc, setGpsLoc] = useState({
    latitude: "",
    longitude: "",
  });
  const [weather, setWeather] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isDBLoading, setIsDBLoading] = useState(false);
  const [isWeatherLoading, setIsWeatherLoading] = useState(false);

  useEffect(() => {
    store.subscribe(() => {
      setUserObj(store.getState().userObj);
      setFavoritePlace(store.getState().favoritePlace);
      setSearchRangeResult(store.getState().searchRangeResult);
      setGpsLoc(store.getState().gpsLoc);
    });
  }, []);

  useEffect(() => {
    async function getSearchResult() {
      if (selectedPlace.address !== "") {
        setIsDBLoading(true);
        setIsWeatherLoading(true);
        await DBManage.searchDBbyRange(
          Number(selectedPlace.latitude),
          Number(selectedPlace.longitude),
          5000
        );
        setIsDBLoading(false);
        const weatherRes = await DBManage.getWeather(
          Number(selectedPlace.latitude),
          Number(selectedPlace.longitude)
        );
        setWeather(weatherRes.data);
        setIsWeatherLoading(false);
      }
    }
    getSearchResult();
  }, [selectedPlace]);

  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="left"
        open={props.open}
        onOpen={() => {}}
        onClose={props.onClose}
        classes={
          isMobile ? { paper: classes.mobilePaper } : { paper: classes.pcPaper }
        }
      >
        <Container className={classes.container}>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item xs={11}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textPrimary"
                  >
                    VENTICKET
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={props.onClose} color="button">
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Typography style={{ margin: "20px" }}>
            {userObj !== null
              ? userObj.displayName + " 님을 위해 준비했어요 🥰"
              : "로그인하고 관심 지역 날씨 정보 / 티켓 추천 받아보세요 😁"}
          </Typography>
          <Divider />
          <div style={{ width: "300px", height: "65px" }}>
            <FormControl fullWidth size="small" style={{ margin: "20px" }}>
              <InputLabel>관심 지역 선택</InputLabel>
              <Select
                onChange={(event) => {
                  if(event.target.value === "현재 위치"){
                    setSelectedPlace({
                      address: "현재 위치",
                      latitude: gpsLoc.latitude,
                      longitude: gpsLoc.longitude,
                    });
                  } else if (favoritePlace !== "") {
                    const placeInfo = favoritePlace.find((e) => {
                      if (e.address === event.target.value) {
                        return true;
                      } else{
                        return false;
                      }
                    });
                    setSelectedPlace({
                      address: placeInfo.address,
                      latitude: placeInfo.latitude,
                      longitude: placeInfo.longitude,
                    });
                  } else{
                  }
                }}
                value={selectedPlace !== "" ? selectedPlace.address : ""}
                label="관심 지역 선택"
              >
                <MenuItem key="gps" value="현재 위치">
                  현재 위치
                </MenuItem>
                {favoritePlace !== "" ? (
                  favoritePlace &&
                  favoritePlace.map((e, index) => {
                    return (
                      <MenuItem key={index} value={e.address}>
                        {e.address}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem key="" value="" disabled>
                    관심 지역을 설정해주세요
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
          <div style={{ height: "calc(100vh - 415px)" }}>
            {getContentHeader(weather, isWeatherLoading)}
            <List
              style={{ maxHeight: "100%", overflow: "auto", marginTop: "2px" }}
            >
              {isDBLoading ? (
                <div style={{ textAlign: "center", marginTop: "45px" }}>
                  <CircularProgress size="37px" color="inherit" />
                </div>
              ) : selectedPlace.address === "" ? (
                <ListItem>
                  <ListItemText>
                    <Typography variant="subtitle1">
                      관심 지역을 선택해주세요 🙄
                    </Typography>
                  </ListItemText>
                </ListItem>
              ) : searchRangeResult.length === 0 ? (
                <ListItem>
                  <ListItemText>
                    <Typography variant="subtitle1">
                      근처 할인 티켓이 없어요 😥
                    </Typography>
                  </ListItemText>
                </ListItem>
              ) : (
                searchRangeResult && searchRangeResult.map((e, index) => {
                  return (
                    <ProductListItem
                      key={index}
                      productInfo={e}
                      onClose={props.onClose}
                      showBackdrop={(show)=>{setShowBackdrop(show)}}
                    />
                  );
                })
              )}
            </List>
          </div>
          <div className={classes.ad}>
            <KakaoAdFit
              adUnit="DAN-a3SoCL1ua1el1aSc"
              adWidth="320"
              adHeight="100"
            />
          </div>
        </Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </SwipeableDrawer>
    </ThemeProvider>
  );
};

export default withStyles(styles)(RecommendDrawer);
