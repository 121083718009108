import React, { useState, useEffect } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Button,
  IconButton,
  Avatar,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SettingsIcon from "@mui/icons-material/Settings";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { COLORS } from "styles/colors";
import { useMediaQuery } from "react-responsive";

import KakaoAdFit from "components/AppBar/Auth/KakaoAdFit";
import store from "store";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    heart: { main: COLORS.orangered },
    location: { main: COLORS.cornflowerblue },
    button: { main: COLORS.black },
    text: { primary: COLORS.black, secondary: COLORS.gray },
  },
  typography: {
    footer: {
        fontSize: 10
    }
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "500px",
  },
  mobilePaper: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
  avatar: {
    marginRight: "20px",
    width: "40px",
    height: "40px",
  },
  footer: {
    position: "absolute",
    width: "95%",
    bottom: "120px",
    margin: "0 0 0 0",
  },
  ad: {
    position: "absolute",
    bottom: "5px",
    width: "90%",
    margin: "auto",
    overflow: "hidden",
  },
});

const AuthDrawer = (props) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);

  useEffect(() => {
    store.subscribe(() => {
      setUserObj(store.getState().userObj);
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const getUserInfoArea = (userObj) => {
    if (userObj === null) {
      return (
        <List>
          <ListItem>
            <Button onClick={props.showSigninDialog}>
              <ListItemIcon>
                <Avatar src="/broken-image.jpg" className={classes.avatar} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="subtitle2"
                  align="center"
                  color="textPrimary"
                >
                  로그인 / 회원가입
                </Typography>
              </ListItemText>
            </Button>
          </ListItem>
        </List>
      );
    } else {
      return (
        <List>
          <ListItem
            secondaryAction={
              <IconButton
                onClick={() => {
                  props.showAuthOptionDrawer();
                }}
                edge="end"
              >
                <SettingsIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar src={userObj.photoURL} className={classes.avatar} />
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="subtitle1">{userObj.displayName}</Typography>
              <Typography variant="caption">{userObj.email}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onOpen={() => {}}
        onClose={props.onClose}
        classes={
          isMobile ? { paper: classes.mobilePaper } : { paper: classes.pcPaper }
        }
      >
        <Container className={classes.container}>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <IconButton
                    onClick={props.onClose}
                    edge="start"
                    color="inherit"
                    aria-label="backtohome"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    color="textPrimary"
                  >
                    MY MENU
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {getUserInfoArea(userObj)}
          <Divider />
          <List>
            <ListItem>
              <ListItemButton onClick={props.showFavoriteProductDrawer}>
                <ListItemIcon>
                  <FavoriteIcon color="heart" />
                </ListItemIcon>
                관심 상품
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={props.showFavoritePlaceDrawer}>
                <ListItemIcon>
                  <LocationOnIcon color="location" />
                </ListItemIcon>
                관심 지역
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <div className={classes.footer}>
            <Divider />
            <Typography variant="footer">
              벤티켓은 쿠팡 파트너스 활동의 일환으로 쿠팡에서 수수료를 제공받을 수
              있습니다.
            </Typography>
            <br/>
            <Typography variant="footer">
              광고/제휴 문의 : venticket@gmail.com
            </Typography>
            
          </div>
          <div className={classes.ad}>
            <KakaoAdFit
              adUnit="DAN-a3SoCL1ua1el1aSc"
              adWidth="320"
              adHeight="100"
            />
          </div>
        </Container>
      </SwipeableDrawer>
    </ThemeProvider>
  );
};

export default withStyles(styles)(AuthDrawer);
