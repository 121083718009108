import React, { useState, useEffect } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
  AppBar,
  Toolbar,
  Typography,
  Grid,
  IconButton,
  Backdrop,
  SwipeableDrawer,
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  ListItemButton,
  Divider,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { COLORS } from "styles/colors";
import { useMediaQuery } from "react-responsive";

import * as DBManage from "functions/DBManage";
import store from "store";
import FavoritePlaceSearchDialog from "components/AppBar/Auth/FavoritePlace/FavoritePlaceSearchDialog";

require("dotenv").config();

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    button: { main: COLORS.black },
    textfield: { main: COLORS.legoyellow },
    location: { main: COLORS.cornflowerblue },
    locationadd: { main: COLORS.forestgreen },
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "500px",
  },
  mobilePaper: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
});

const FavoritePlaceDrawer = (props) => {
  const { classes } = props;
  const [userObj, setUserObj] = useState(null);
  const [favoritePlace, setFavoritePlace] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showFavoritePlaceSearchDialog, setShowFavoritePlaceSearchDialog] =
    useState(false);

  useEffect(() => {
    store.subscribe(() => {
      setUserObj(store.getState().userObj);
      setFavoritePlace(store.getState().favoritePlace);
    });
  }, []);

  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const getMyFavoritePlaceArea = () => {
    if (userObj === null) {
      return (
        <div>
          <List>
            <ListItem>
              로그인하시고 관심지역 주변 상품 추천 받아보세요!
            </ListItem>
          </List>
          <Divider />
        </div>
      );
    } else {
      return (
        <div>
          <List>
            {favoritePlace !== "" ? (
              favoritePlace &&
              favoritePlace.map((e, index) => {
                return (
                  <ListItem
                    key={index}
                    disablePadding
                    style={{margin:"10px 0 10px 0"}}
                    secondaryAction={
                      <IconButton
                        onClick={async () => {
                          setShowBackdrop(true);
                          await DBManage.deleteUserFavoritePlace(
                            userObj.uid,
                            e.address
                          );
                          setShowBackdrop(false);
                        }}
                        edge="end"
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton>
                      <ListItemIcon style={{minWidth: "40px"}}>
                        <LocationOnIcon color="location" />
                      </ListItemIcon>
                      {e.address}
                    </ListItemButton>
                  </ListItem>
                );
              })
            ) : (
              <div />
            )}
          </List>
          <Divider />
          <List>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  setShowFavoritePlaceSearchDialog(true);
                }}
              >
                <ListItemIcon>
                  <AddLocationAltIcon color="locationadd" />
                </ListItemIcon>
                관심 지역 추가하기
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SwipeableDrawer
        anchor="right"
        open={props.open}
        onOpen={() => {}}
        onClose={props.onClose}
        classes={
          isMobile ? { paper: classes.mobilePaper } : { paper: classes.pcPaper }
        }
      >
        <Container className={classes.container}>
          <AppBar position="static" style={{ boxShadow: "none" }}>
            <Toolbar>
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      props.onClose();
                    }}
                    edge="start"
                    color="inherit"
                    aria-label="backtohome"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textPrimary"
                  >
                    관심 지역 설정
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          {getMyFavoritePlaceArea()}
        </Container>
      </SwipeableDrawer>
      <FavoritePlaceSearchDialog
        open={showFavoritePlaceSearchDialog}
        onClose={() => {
          setShowFavoritePlaceSearchDialog(false);
        }}
        addFavoritePlace={async (address, latitude, longitude) => {
          setShowFavoritePlaceSearchDialog(false);
          setShowBackdrop(true);
          await DBManage.addUserFavoritePlace(
            userObj.uid,
            address,
            latitude,
            longitude
          );
          setShowBackdrop(false);
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default withStyles(styles)(FavoritePlaceDrawer);
