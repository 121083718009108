/*global kakao */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import store from "store";
import * as DBManage from "functions/DBManage";
import InfoDisplay from "components/Map/InfoDisplay";
import "styles/CustomOverlay.css";
import { useMediaQuery } from "react-responsive";
import { COLORS } from "styles/colors";

import {
  Button,
  ButtonGroup,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  List,
  Backdrop,
  CircularProgress,
  DialogContent,
} from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import ProductListItem from "components/ProductListItem";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
  },
});

const styles = (theme) => ({
  pcPaper: {
    width: "500px",
    position: "absolute",
    right: "0",
  },
  mobilePaper: {
    width: "100%",
  },
  mapviewExpand: {
    width: "100%",
    height: "calc(100vh - 106px)",
  },
  userControl: {
    position: "absolute",
    top: "125px",
    right: "10px",
    width: "40px",
    overflow: "hidden",
    zIndex: "1",
  },
});

const MapView = forwardRef((props, ref) => {
  const { classes } = props;
  let map, clusterer, tempProductId, tempClusterCenter;
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const [globalMap, setGlobalMap] = useState();
  const [gpsMarker, setGpsMarker] = useState();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [clustererDBGroup, setClustererDBGroup] = useState([]);
  const [loadedClustererNumber, setLoadedClustererNumber] = useState(0);
  const [clustererNumber, setClustererNumber] = useState();
  const [isDisplayInfo, setIsDisplayInfo] = useState(false);
  const [isDBLoaded, setIsDBLoaded] = useState(false);
  const [isDisplayClustererInfo, setIsDisplayClustererInfo] = useState(false);
  const [isClustererDBGroupLoaded, setIsClustererDBGroupLoaded] = useState(false);
  const [isComparisonDBLoaded, setIsComparisonDBLoaded] = useState(true);

  useImperativeHandle(ref, () => ({
    setNativeLocation(latitude, longitude){
      setCurrentLocation(globalMap, "NATIVE", latitude, longitude);
    },
    backHandler(){
      setIsDisplayInfo(false);
      setIsDisplayClustererInfo(false);
    },
  }));

  const requestGPS = (map) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "REQ_GPS" })
      );
    } else{
      setCurrentLocation(map, "WEB");
    }
  };

  useEffect(() => {
    initializeMap();
    store.subscribe(() => {
      createMarkerClusterer();
      if (store.getState().isSearchSelected) {
        setIsDisplayInfo(false);
        panToLocation(
          store.getState().productInfo._geoloc.lat,
          store.getState().productInfo._geoloc.lng
        );
        setIsDBLoaded(true);
        setIsDisplayInfo(true);
      }
    });
  }, []);

  /* Function: initializeMap -----------------------------------------
   * - create map
   * - pan to GPS location
   * - search products by address based on store
   * - create marker for products
   * - add listner to map
   * ----------------------------------------------------------------*/
  const initializeMap = async () => {
    //Create MAP
    map = new kakao.maps.Map(
      //Map Container
      document.getElementById("map"),
      //Map Option
      { center: new kakao.maps.LatLng(37.506502, 127.053617), level: 6 }
    );

    //Set GlobalMap (useState - for Global functions)
    setGlobalMap(map);

    //Create Marker Clusterer
    clusterer = new kakao.maps.MarkerClusterer({
      map: map,
      averageCenter: true,
      minLevel: 1,
      disableClickZoom: true,
    });

    //Add Event Listener to Clusterer (Click)
    kakao.maps.event.addListener(clusterer, "clusterclick", async (cluster) => {
      let level = map.getLevel();
      if (level <= 5) {
        if(cluster._center !== tempClusterCenter){
          let loadedNumber = 0;
          tempClusterCenter = cluster._center;
          setIsClustererDBGroupLoaded(false);
          setLoadedClustererNumber(loadedNumber);
          setClustererNumber(cluster._markers.length);
          setIsDisplayClustererInfo(true);
          setClustererDBGroup([]);
          const clustererDBGroupArr = await Promise.all(
            cluster._markers.map(async (e) => {
              const productInfo = await e.o.rightclick[0].Pf();
              loadedNumber = loadedNumber + 1;
              setLoadedClustererNumber(loadedNumber);
              return {
                productId: productInfo.productId,
                productName: productInfo.productName,
                productDetailType: productInfo.productDetailType,
                originalPrice: productInfo.originalPrice,
                salePrice: productInfo.salePrice,
                discountedSalePrice: productInfo.discountedSalePrice,
                priceDescription: productInfo.priceDescription,
                ratingAverage: productInfo.ratingAverage,
                ratingCount: productInfo.ratingCount,
                latitude: productInfo._geoloc.lat,
                longitude: productInfo._geoloc.lng,
                thumbnail: productInfo.thumbnail,
                url: productInfo.url,
                _geoloc: productInfo._geoloc,
              };
            })
          );
          setClustererDBGroup(clustererDBGroupArr);
          setIsClustererDBGroupLoaded(true);
        } else{
          setIsDisplayClustererInfo(true);
          setIsClustererDBGroupLoaded(true);
        }
      } else {
        map.setLevel(level - 1, { anchor: cluster.getCenter() });
      }
    });

    //Move to Current Location
    requestGPS(map);

    //Create Marker Clusterer
    createMarkerClusterer();

    //Add Listener - at click event on map
    kakao.maps.event.addListener(map, "click", () => {
      //Resize Map Content to Full-width && Remove Display Info Window
      setIsDisplayInfo(false);
      setIsDBLoaded(false);
      store.dispatch({
        type: "SELECTSEARCHRESULT",
        isSearchSelected: false,
      });
      //Re-Layout
      map.relayout();
    });
  };

  /* Function: createMarkerClusterer ----------------------------------------
   * - create marker clusterer on map
   * - add listener to marker (click event)
   * ----------------------------------------------------------------*/
  const createMarkerClusterer = () => {
    clusterer.clear();
    let markers = store.getState().clustererDB.map((clustererDB) => {
      //Define Marker
      let marker = new kakao.maps.Marker({
        position: new kakao.maps.LatLng(
          clustererDB.latitude,
          clustererDB.longitude
        ),
      });
      //Marker Click Event Listner
      kakao.maps.event.addListener(marker, "click", async () => {
        setIsDBLoaded(false);
        setIsComparisonDBLoaded(false);
        setIsDisplayInfo(true);
        if(clustererDB.productId !== tempProductId){
          tempProductId = clustererDB.productId;
          const resDB = await DBManage.requestDB(Number(clustererDB.productId), true);
          setIsDBLoaded(true);
          await DBManage.requestComparisonDB(resDB.productName);
          setIsComparisonDBLoaded(true);
        } else{
          setIsDBLoaded(true);
          setIsComparisonDBLoaded(true);
        }        
      });
      //Marker Right-Click Event Listner: for Clusterer Handling (Not In Use)
      kakao.maps.event.addListener(marker, "rightclick", async () => {
        const resDB = await DBManage.requestDB(
          Number(clustererDB.productId),
          false
        );
        return resDB;
      });

      return marker;
    });
    clusterer.addMarkers(markers);
  };

  /* Function: setCurrentLocation ------------------------------------
   * - get GPS location, create marker, then pan to GPS location
   * ----------------------------------------------------------------*/
  const setCurrentLocation = async (map, opt, latitude, longitude) => {
    let gpsPosition, isGpsLoaded;
    
    if (gpsMarker !== undefined) {
      gpsMarker.setMap(null);
    }
    
    if(opt==="WEB" && navigator.geolocation){
      const webPosition = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      gpsPosition = new kakao.maps.LatLng(
        webPosition.coords.latitude,
        webPosition.coords.longitude
      );
      store.dispatch({
        type: "GPSLOC",
        latitude: webPosition.coords.latitude,
        longitude: webPosition.coords.longitude,
      });
      isGpsLoaded = true;
    } else if(opt==="NATIVE"){
      gpsPosition = new kakao.maps.LatLng(latitude, longitude);
      store.dispatch({
        type: "GPSLOC",
        latitude: latitude,
        longitude: longitude,
      });
      isGpsLoaded = true;
    } else{
      isGpsLoaded = false;
    }
    
    if(isGpsLoaded){
      let gpsMarkerImage = new kakao.maps.MarkerImage(
        "https://ssl.pstatic.net/static/maps/m/pin_rd.png",
        new kakao.maps.Size(20, 20)
      );
      let newGpsMarker = new kakao.maps.Marker({
        position: gpsPosition,
        image: gpsMarkerImage,
      });
      setGpsMarker(newGpsMarker);
      newGpsMarker.setMap(map);
      map.panTo(gpsPosition);
    }
  };

  /* Function: panToLocation ------------------------------------
   * - pan to location, then change map level
   * ----------------------------------------------------------------*/
  const panToLocation = (latitude, longitude) => {
    map.setLevel(2);
    map.panTo(new kakao.maps.LatLng(latitude, longitude));
  };

  /* Function: zoomInOut --------------------------------------------
   * - sets zoom level of map
   * ----------------------------------------------------------------*/
  const zoomInOut = (map, option) => {
    if (option === "in") {
      map.setLevel(map.getLevel() - 1);
    } else if (option === "out") {
      map.setLevel(map.getLevel() + 1);
    }
  };

  return (
    <div>
      <div id="map" className={classes.mapviewExpand} />
      <ThemeProvider theme={theme}>
        <ButtonGroup
          className={classes.userControl}
          orientation="vertical"
          color="primary"
          variant="contained"
        >
          <Button
            onClick={() => {
              requestGPS(globalMap);
            }}
          >
            <MyLocationIcon />
          </Button>
          <Button
            onClick={() => {
              zoomInOut(globalMap, "in");
            }}
          >
            <AddIcon />
          </Button>
          <Button
            onClick={() => {
              zoomInOut(globalMap, "out");
            }}
          >
            <RemoveIcon />
          </Button>
        </ButtonGroup>
      </ThemeProvider>
      {isDisplayInfo ? (
        <div
          className = { isMobile ? classes.mobilePaper : classes.pcPaper }
        >
        <InfoDisplay
          isDBLoaded={isDBLoaded}
          isComparisonDBLoaded={isComparisonDBLoaded}
          showBackdrop={(show)=>{setShowBackdrop(show)}}
        />
        </div>
      ) : (
        <div />
      )}

      <Dialog
        open={isDisplayClustererInfo}
        onClose={() => {
          setIsDisplayClustererInfo(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ backgroundColor: COLORS.legoyellow, height: "10px"}}>
          <Typography variant="subtitle2" display="inline" style={{ position:"absolute", top:"10px"}}>
            여기에 {clustererNumber}개의 상품이 있어요
          </Typography>
          <IconButton
            onClick={() => {
              setIsDisplayClustererInfo(false);
            }}
            style={{ position: "absolute", top: "0", right: "5px" }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding:"0px 0px 0px 0px"}}>
          {isClustererDBGroupLoaded ? (
            <List disablePadding style={{ maxHeight: "50vh", overflow: "auto" }}>
              {clustererDBGroup.map((e, index) => {
                return (
                  <ProductListItem
                    key={index}
                    productInfo={e}
                    onClose={() => {setIsDisplayClustererInfo(false);}}
                    showBackdrop={(show)=>{setShowBackdrop(show)}}
                  />
                );
              })}
            </List>
          ) : (
            <div style={{margin:"25px 25px 30px "}}>
              <CircularProgress size="14px" style={{ marginRight: "10px" }} />
              <Typography variant="caption">
                {"상품을 불러오는 중입니다...("+loadedClustererNumber+"/"+clustererNumber+")"}
              </Typography>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
});

export default withStyles(styles)(MapView);
