import React from "react";

import { withStyles } from "@mui/styles";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";

import * as DBManage from "functions/DBManage";

const styles = (theme) => ({
});

const ProductListItem = (props) => {
  const { classes } = props;
  return (
    <ListItem key={props.key} disablePadding>
      <ListItemButton
        onClick={async () => {
          props.showBackdrop(true);
          await DBManage.selectSearchResult(props.productInfo);
          props.showBackdrop(false);
          props.onClose();
        }}
      >
        <ListItemIcon>
          <img
            src={props.productInfo.thumbnail}
            style={{ height: "45px", width: "45px" }}
            alt=""
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listitemtext }}
          primary={props.productInfo.productName}
          secondary={
            <span>
              <Rating
                readOnly
                size="small"
                precision={0.1}
                value={props.productInfo.ratingAverage}
              />
              <Typography variant="caption" style={{marginLeft:"5px"}}>
                {props.productInfo.salePrice}원
              </Typography>
            </span>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default withStyles(styles)(ProductListItem);
