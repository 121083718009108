import React, { useEffect } from "react";

import store from "../store";
import * as DBManage from "functions/DBManage";
import { COLORS } from "styles/colors";

import { withStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLORS.legoyellow,
    width: "100%",
    height: "100vh",
  },
  grid: {
    width: "100%",
    height: "100%",
  },
});

const Loading = (props) => {
  const { classes } = props;

  useEffect(() => {
    async function initialize() {
      await DBManage.requestClusterer("FESTIVAL_AND_THEME");
      //Notify "isLoaded" to Router.js
      store.dispatch({
        type: "loadComplete",
      });
    }
    initialize();
  }, []);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        className={classes.grid}
      >
        <Grid item>
          <img
            src="https://storage.googleapis.com/venti-99dcc.appspot.com/ventilogo.png"
            style={{ height: "57px", width: "100px" }}
            alt=""
          />
        </Grid>
        <Grid item>
          <Typography variant="h6">VENTICKET</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Loading);
