import React, { useState } from "react";
import axios from "axios";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  Button,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import { COLORS } from "styles/colors";

const FavoritePlaceSearchDialog = (props) => {
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const addressSearch = async (keyword) => {
    const res = await axios({
      method: "get",
      url:
        "https://dapi.kakao.com//v2/local/search/address.json?query=" + keyword,
      headers: {
        Authorization: "KakaoAK " + process.env.REACT_APP_KAKAO_REST_API_KEY,
      },
    });
    if (res.data.documents.length === 0) {
      setSearchResult(null);
    } else {
      let addressArray = [];
      res.data.documents.forEach((e) => {
        addressArray.push({
          address_name: e.address_name,
          latitude: e.y,
          longitude: e.x,
        });
      });
      setSearchResult(addressArray);
    }
  };

  const getSearchResult = () => {
    return (
      <Grid>
        <List>
          {searchResult !== null ? (
            searchResult.map((e) => {
              return (
                <ListItem>
                  <ListItemButton
                    onClick={() => {
                      props.addFavoritePlace(
                        e.address_name,
                        e.latitude,
                        e.longitude
                      );
                    }}
                  >
                    <ListItemIcon style={{ minWidth: "40px" }}>
                      <AddLocationAltIcon />
                    </ListItemIcon>
                    <Typography variant="button">{e.address_name}</Typography>
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <div style={{ margin: "10px" }}>
              <Typography>검색 결과가 없습니다 😥</Typography>
              <Typography>정확한 시/군/구/동 명을 입력해주세요.</Typography>
              <Typography variant="caption">
                ex) 서울시 or 서초구 or 서초동
              </Typography>
            </div>
          )}
        </List>
      </Grid>
    );
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ backgroundColor: COLORS.legoyellow }}>
        <Typography variant="subtitle1" align="center">
          관심 지역 검색
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid item>
            <TextField
              label="지역명 검색"
              fullWidth
              placeholder="ex) 성남시 or 수지구"
              onChange={(event) => {
                setKeyword(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  addressSearch(keyword);
                }
              }}
              color="textfield"
              style={{ marginBottom: "10px", marginTop: "20px" }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              onClick={() => {
                addressSearch(keyword);
              }}
              color="button"
              variant="outlined"
              style={{ margin: "5px" }}
            >
              검색
            </Button>
            <Button
              onClick={props.onClose}
              color="button"
              variant="outlined"
              style={{ margin: "5px" }}
            >
              취소
            </Button>
          </Grid>
        </Grid>
        <Divider style={{ marginTop: "10px" }} />
        {getSearchResult()}
      </DialogContent>
    </Dialog>
  );
};

export default FavoritePlaceSearchDialog;
