import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import store from "store";
import Loading from "routes/Loading";
import Home from "routes/Home";

const AppRouter = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWaitTimeComplete, setIsWaitTimeComplete] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsWaitTimeComplete(true);
    }, 1000);
    store.subscribe(() => {
      setIsLoaded(store.getState().isLoaded);
    });
  }, []);
  return (
    <Router>
      <Switch>
        {isWaitTimeComplete && isLoaded ? (
          <Route>
            <Home />
          </Route>
        ) : (
          <Route>
            <Loading />
          </Route>
        )}
      </Switch>
    </Router>
  );
};

export default AppRouter;
