import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  CircularProgress,
  Grid,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Container,
  Button,
  IconButton,
  ButtonGroup,
  Rating,
  Snackbar,
  Slide,
  Backdrop,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AnimateHeight from "react-animate-height";
import { COLORS } from "styles/colors";

import * as DBManage from "functions/DBManage";
import store from "../../store";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.legoyellow },
    secondary: { main: COLORS.orangered },
    zzim: { main: COLORS.orangered },
    share: { main: COLORS.midnightblue },
    goshop: { main: COLORS.midnightblue }
  },
  typography: {
    snackbar: {
      fontSize: 14,
      fontFamily: "NanumSquareRoundB",
    },
    goshop: {
      fontSize: 14,
      fontFamily: "NanumSquareRoundB",
    },
  },
});

const styles = (theme) => ({
  animateheight: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "100%",
    overflow: "hidden",
    zIndex: "1",
    backgroundColor: COLORS.white,
    borderRadius: "20px 20px 0 0",
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const calcSaleRating = (originalPrice, price) => {
  return Math.floor(((originalPrice - price) / originalPrice) * 100);
};

const InfoDisplay = (props) => {
  const { classes } = props;
  const [isExpandView, setIsExpandView] = useState(false);
  const [displayHeight, setDisplayHeight] = useState(230);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarContent, setSnackbarContent] = useState("");
  const [isMyFavorite, setIsMyFavorite] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    if(props.isDBLoaded){
      if(store.getState().productInfo.isDeleted){
        setIsDeleted(true);
      } else{
        setIsDeleted(false);
      }

      const trgtId = store.getState().productInfo.productId;
      const productArr = store.getState().favoriteProduct;
      setIsMyFavorite(false);
      for(let i=0; i<productArr.length; i++){
        if(productArr[i].productInfo.productId === trgtId){
          setIsMyFavorite(true);
        }
      }
    }
  }, [props.isDBLoaded]);

  const swipeExpand = (touchStart, touchEnd) => {
    if (touchStart - touchEnd > 30) {
      toggleExpand(true);
    } else if (touchStart - touchEnd < -30) {
      toggleExpand(false);
    } else{
    }
  };

  const toggleExpand = (key) => {
    if (key) {
      setIsExpandView(true);
      setDisplayHeight(500);
    } else {
      setIsExpandView(false);
      setDisplayHeight(230);
    }
  };

  const copyClipboard = (content) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: "REQ_CLIPBOARD", content: content })
      );
    } else{
      navigator.clipboard.writeText(content); 
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AnimateHeight height={displayHeight} className={classes.animateheight}>
        <div style={{ height: "10px", backgroundColor: COLORS.legoyellow }} />
        {props.isDBLoaded ? (
          <div>
            <div
              onClick={() => {
                toggleExpand(!isExpandView);
              }}
              onTouchStart={(e) => {
                setTouchStart(e.targetTouches[0].clientY);
              }}
              onTouchMove={(e) => {
                setTouchEnd(e.targetTouches[0].clientY);
                if(touchStart - e.targetTouches[0].clientY > 30){
                  setDisplayHeight(
                    Math.min(
                      Math.max(displayHeight + (touchStart - e.targetTouches[0].clientY) / 20, 150),
                      800
                    )
                  );
                }
              }}
              onTouchEnd={() => {
                swipeExpand(touchStart, touchEnd);
              }}
              style={{ height: "160px" }}
            >
              <div
                style={{
                  float: "left",
                  width: "calc(100% - 150px)",
                  margin: "10px 20px 0 20px",
                  position: "absolute"
                }}
              >
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isDeleted}
                  style={{position: "absolute", borderRadius: "25px"}}
                >
                  <Typography variant="subtitle1">
                    판매 기간이 만료되었어요ㅠㅠ
                  </Typography>
                </Backdrop>
                <div style={{overflow: "hidden", textOverflow: "ellipsis", height:"60px"}}>
                  <Typography variant="subtitle1" nowrap>
                    {store.getState().productInfo.productName}
                  </Typography>
                </div>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>
                    <Rating
                      readOnly
                      size="small"
                      precision={0.1}
                      value={store.getState().productInfo.ratingAverage}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {store.getState().productInfo.ratingAverage.toFixed(1)} / 5.0 ({store.getState().productInfo.ratingCount})
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    {store.getState().productInfo.originalPrice === null ||
                    store.getState().productInfo.originalPrice === undefined ? (
                      <span>
                        <Typography
                          variant="body2"
                          style={{ display: "inline-block" }}
                        >
                          {store.getState().productInfo.salePrice}원
                        </Typography>
                      </span>
                    ) : (
                      <span>
                        <Chip
                          label={
                            calcSaleRating(
                              store.getState().productInfo.originalPrice,
                              store.getState().productInfo.salePrice
                            ) + "%할인"
                          }
                          size="small"
                          color="secondary"
                          style={{ marginRight: "5px" }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            display: "inline-block",
                            textDecoration: "line-through",
                          }}
                        >
                          {store.getState().productInfo.originalPrice}원
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ display: "inline-block" }}
                        >
                          {" "}
                          → {store.getState().productInfo.salePrice}원
                        </Typography>
                      </span>
                    )}
                  </Grid>
                </Grid>
                <div style={{overflow: "hidden", textOverflow: "ellipsis", height:"40px"}}>
                  <Typography
                    variant="caption"
                    style={{ color: COLORS.gray, fontStyle: "italic" }}
                  >
                    {store.getState().productInfo.priceDescription}
                  </Typography>
                </div>
              </div>
              <img
                src={store.getState().productInfo.thumbnail}
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "10px 10px 0 calc(100% - 150px + 40px)",
                }}
                alt=""
              />
            </div>
            <Divider />
            <ButtonGroup
              variant="outlined"
              // size="small"
              style={{
                float: "left",
                margin: "5px 0 0 10px",
              }}
            >
              <IconButton
                onClick={async (e) => {
                  e.stopPropagation();
                  if(store.getState().userObj === null){
                    setSnackbarContent("로그인이 필요합니다");
                    setSeverity("error");
                    setOpenSnackbar(true);
                  } else{
                    if(!isMyFavorite){
                      setIsMyFavorite(true);
                      props.showBackdrop(true);
                      await DBManage.addUserFavoriteProduct(store.getState().userObj.uid, store.getState().productInfo);
                      props.showBackdrop(false);
                    } else{
                      setIsMyFavorite(false);
                      props.showBackdrop(true);
                      await DBManage.deleteUserFavoriteProduct(store.getState().userObj.uid, store.getState().productInfo.productId);
                      props.showBackdrop(false);
                    }
                  }
                }}
                onTouchStart={(e) => {
                  e.stopPropagation();
                }}
                onTouchMove={(e) => {
                  e.stopPropagation();
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                }}
              >
                {isMyFavorite ? <FavoriteIcon color="zzim" /> : <FavoriteBorderIcon color="zzim" />}
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyClipboard(store.getState().productInfo.url);
                  setSnackbarContent("링크 복사 완료!");
                  setSeverity("success");
                  setOpenSnackbar(true);
                }}
                onTouchStart={(e) => {e.stopPropagation();}}
                onTouchMove={(e) => {e.stopPropagation();}}
                onTouchEnd={(e) => {e.stopPropagation();}}
              >
                <ShareIcon  color="share" />
              </IconButton>
            </ButtonGroup>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                window.open(store.getState().productInfo.url, "_blank");
              }}
              onTouchStart={(e) => {e.stopPropagation()}}
              onTouchMove={(e) => {e.stopPropagation();}}
              onTouchEnd={(e) => {e.stopPropagation();}}
              endIcon={<ArrowForwardIcon />}
              disableElevation
              disableRipple
              color="goshop"
              style={{
                float: "right",
                margin: "5px 10px 0 0",
              }}
            >
              구매하러가기
            </Button>

            <Container
              style={{
                float: "left",
                width: "100%",
                margin: "20px 0 0 0",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ margin: "10px 0 10px 0" }}
              >
                유사 상품 가격 비교
              </Typography>
              {props.isComparisonDBLoaded ? (
                <List
                  style={{
                    maxHeight: "220px",
                    overflow: "auto",
                  }}
                >
                  {store.getState().productInfo.comparison &&
                    store.getState().productInfo.comparison.map((e, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            {e.source === "tmon" ? (
                              <img
                                src="https://storage.googleapis.com/venti-99dcc.appspot.com/tmonlogo.png"
                                style={{ height: "40px", width: "40px" }}
                                alt=""
                              />
                            ) : e.source === "wemakeprice" ? (
                              <img
                                src="https://storage.googleapis.com/venti-99dcc.appspot.com/wemakepricelogo.png"
                                style={{ height: "40px", width: "40px" }}
                                alt=""
                              />
                            ) : (
                              <span />
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            primary={e.productName}
                            secondary={
                              e.source === "tmon"
                                ? e.salePrice +
                                  "원 (" +
                                  e.priceDescription +
                                  ")"
                                : e.salePrice + "원"
                            }
                          />
                        </ListItem>
                      );
                    })}
                </List>
              ) : (
                <CircularProgress />
              )}
            </Container>
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "45px" }}>
            <CircularProgress />
          </div>
        )}
      </AnimateHeight>
      <div style={{width:"100%"}}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
        style={{ width: "200px" }}
        TransitionComponent={SlideTransition}
      >
        <Alert severity={severity}>
          <Typography variant="snackbar">{snackbarContent}</Typography>
        </Alert>
      </Snackbar>
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(InfoDisplay);
