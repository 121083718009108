import {createStore} from 'redux';

import * as DBManage from "functions/DBManage";

export default createStore(function(state,action){
  if(state === undefined){
    return {
      isLoaded:false, //is Loading Complete
      userObj:null, //User Object
      gpsLoc:{
        latitude: null,
        longitude: null,
      },
      favoritePlace:'', //User's Favorite Places
      favoriteProduct:'', //User's Favorite Products
      category:'FESTIVAL_AND_THEME', //Selected Category
      clustererDB:[], //Requested Clusterer DB
      searchResult:[], //Search Result
      searchRangeResult:[], //Search Result (Near User's Favorite Place)
      isSearchSelected:false, //is Search Selected
      productInfo:{
        productId: '',
        productName: '',
        productDetailType: '',
        originalPrice: '',
        salePrice: '',
        discountedSalePrice: '',
        priceDescription: '',
        ratingAverage: '',
        ratingCount: '',
        _geoloc: '',
        thumbnail: '',
        url: '',
        comparison:[],
        isDeleted: false,
      },
    };
  }
  switch(action.type){
    case 'loadComplete':
      state.isLoaded = true;
      return state;
    case 'SETUSER':
      state.userObj = action.userObj;
      return state;
    case 'GPSLOC':
      state.gpsLoc.latitude = action.latitude;
      state.gpsLoc.longitude = action.longitude;
      return state;
    case 'setFavoritePlace':
      state.favoritePlace = action.favoritePlace;
      return state;
    case 'setFavoriteProduct':
      state.favoriteProduct = action.favoriteProduct;
      return state;
    case 'CLEARUSER':
      state.userObj = [];
      return state;
    case 'CATEGORY':
      state.category = action.category;
      DBManage.requestClusterer(action.category);
      return state;
    case 'CLUSTERERDB':
      state.clustererDB = action.clustererDB;
      return state;
    case 'SEARCHRESULT':
      state.searchResult = action.searchResult;
      return state;
    case 'searchRangeResult':
      state.searchRangeResult = action.searchRangeResult;
      return state;
    case 'SELECTSEARCHRESULT':
      state.isSearchSelected = action.isSearchSelected;
      return state;
    case 'selectPlace':
      state.productInfo.productId = action.productId;
      state.productInfo.productName = action.productName;
      state.productInfo.productDetailType = action.productDetailType;
      state.productInfo.originalPrice = action.originalPrice;
      state.productInfo.salePrice = action.salePrice;
      state.productInfo.discountedSalePrice = action.discountedSalePrice;
      state.productInfo.priceDescription = action.priceDescription;
      state.productInfo.ratingAverage = action.ratingAverage;
      state.productInfo.ratingCount = action.ratingCount;
      state.productInfo._geoloc = action._geoloc;
      state.productInfo.thumbnail = action.thumbnail;
      state.productInfo.url = action.url;
      state.productInfo.comparison = [];
      state.productInfo.isDeleted = action.isDeleted;
      return state;
    case 'UPDATECOMPARISON':
      state.productInfo.comparison = action.comparison;
      return state;
    default:
      return state;
  }
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
