import axios from "axios";
import store from "../store";

export async function requestClusterer(productDetailType) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getClustererDB",
    { productDetailType: productDetailType }
  );
  await store.dispatch({
    type: "CLUSTERERDB",
    clustererDB: res.data,
  });
  return res;
}

export async function requestDB(productId, dispatchOpt) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getVentiDB",
    { productId: productId }
  );
  if(dispatchOpt){
    await store.dispatch({
      type: "selectPlace",
      productId: res.data[0].productId,
      productName: res.data[0].productName,
      productDetailType: res.data[0].productDetailType,
      originalPrice: res.data[0].originalPrice,
      salePrice: res.data[0].salePrice,
      discountedSalePrice: res.data[0].discountedSalePrice,
      priceDescription: res.data[0].priceDescription,
      ratingAverage: res.data[0].ratingAverage,
      ratingCount: res.data[0].ratingCount,
      _geoloc: res.data[0]._geoloc,
      thumbnail: res.data[0].thumbnail,
      url: res.data[0].url,
      isDeleted: false,
    });
  }
  return res.data[0];
}

export async function requestComparisonDB(productTitle) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getProductComparison",
    { productTitle: productTitle }
  );
  await store.dispatch({
    type: "UPDATECOMPARISON",
    comparison: res.data,
  });
  return res;
}


export async function searchDB(keyword) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/searchDB",
    { keyword: keyword }
  );
  await store.dispatch({
    type: "SEARCHRESULT",
    searchResult: res.data,
  });
  return res;
}

export async function searchDBbyRange(latitude, longitude, range) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/searchDBbyRange",
    { latitude: latitude, longitude: longitude, range: range }
  );
  await store.dispatch({
    type: "searchRangeResult",
    searchRangeResult: res.data,
  });
  return res;
}

export async function selectSearchResult(productInfo) {
  await store.dispatch({
    type: "CATEGORY",
    category: productInfo.productDetailType,
  });
  const dataRes = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getVentiDB",
    { productId: productInfo.productId }
  );
  if(Array.isArray(dataRes.data)&&dataRes.data.length===0){
    await store.dispatch({
      type: "selectPlace",
      productId: productInfo.productId,
      productName: productInfo.productName,
      productDetailType: productInfo.productDetailType,
      originalPrice: productInfo.originalPrice,
      salePrice: productInfo.salePrice,
      discountedSalePrice: productInfo.discountedSalePrice,
      priceDescription: productInfo.priceDescription,
      ratingAverage: productInfo.ratingAverage,
      ratingCount: productInfo.ratingCount,
      _geoloc: productInfo._geoloc,
      thumbnail: productInfo.thumbnail,
      url: productInfo.url,
      isDeleted: true,
    });
  } else{
    await store.dispatch({
      type: "selectPlace",
      productId: productInfo.productId,
      productName: productInfo.productName,
      productDetailType: productInfo.productDetailType,
      originalPrice: productInfo.originalPrice,
      salePrice: productInfo.salePrice,
      discountedSalePrice: productInfo.discountedSalePrice,
      priceDescription: productInfo.priceDescription,
      ratingAverage: productInfo.ratingAverage,
      ratingCount: productInfo.ratingCount,
      _geoloc: productInfo._geoloc,
      thumbnail: productInfo.thumbnail,
      url: productInfo.url,
      isDeleted: false,
    });
  }
  
  await store.dispatch({
    type: "SELECTSEARCHRESULT",
    isSearchSelected: true,
  });
  const comparisonRes = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getProductComparison",
    { productTitle: productInfo.productName }
  );
  await store.dispatch({
    type: "UPDATECOMPARISON",
    comparison: comparisonRes.data,
  });
}

export async function getUserFavoritePlace(uid) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getUserFavoritePlace",
    { uid: uid }
  );
  await store.dispatch({
    type: "setFavoritePlace",
    favoritePlace: res.data,
  });
}

export async function addUserFavoritePlace(uid, address, latitude, longitude) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/addUserFavoritePlace",
    { uid: uid, address: address, latitude: latitude, longitude: longitude }
  );
  await store.dispatch({
    type: "setFavoritePlace",
    favoritePlace: res.data,
  });
}

export async function deleteUserFavoritePlace(uid, address) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/deleteUserFavoritePlace",
    { uid: uid, address: address }
  );
  await store.dispatch({
    type: "setFavoritePlace",
    favoritePlace: res.data,
  });
}

export async function getWeather(latitude, longitude) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getWeather",
    { latitude: latitude, longitude: longitude }
  );
  return res;
}

export async function getUserFavoriteProduct(uid) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/getUserFavoriteProduct",
    { uid: uid }
  );
  await store.dispatch({
    type: "setFavoriteProduct",
    favoriteProduct: res.data,
  });
}

export async function addUserFavoriteProduct(uid, productInfo) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/addUserFavoriteProduct",
    { uid: uid, productInfo: productInfo }
  );
  await store.dispatch({
    type: "setFavoriteProduct",
    favoriteProduct: res.data,
  });
}

export async function deleteUserFavoriteProduct(uid, productId) {
  const res = await axios.post(
    "https://asia-northeast1-venti-99dcc.cloudfunctions.net/deleteUserFavoriteProduct",
    { uid: uid, productId: productId }
  );
  await store.dispatch({
    type: "setFavoriteProduct",
    favoriteProduct: res.data,
  });
}