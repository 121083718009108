import React, { useState } from "react";

import { withStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { COLORS } from "styles/colors";
import store from "store";
import ProductListItem from "components/ProductListItem";

const theme = createTheme({
  palette: {
    primary: { main: COLORS.white },
    button: { main: COLORS.black },
  },
});

const styles = (theme) => ({
  root: {
    position: "absolute",
    top: "64px",
    right: "0",
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "hidden",
    zIndex: "2",
    backgroundColor: COLORS.white,
  },
  bottombar: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    height: "40px",
    textAlign: "right",
    backgroundColor: COLORS.floralwhite,
  },
});

const SearchView = (props) => {
  const { classes } = props;
  const [showBackdrop, setShowBackdrop] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      {props.open ? (
        <div className={classes.root}>
          <List style={{ maxHeight: "calc(100% - 40px)", overflow: "auto" }}>
            {store.getState().searchResult.length > 0 ? (
              store.getState().searchResult.map((e, index) => {
                return (
                  <ProductListItem
                    key={index}
                    productInfo={e}
                    onClose={props.onClose}
                    showBackdrop={(show)=>{setShowBackdrop(show)}}
                  />
                );
              })
            ) : (
              <ListItem>
                <ListItemText>
                  <Typography variant="subtitle1">
                    검색 결과가 없습니다 😥
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </List>
          <div className={classes.bottombar}>
            <Button onClick={props.onClose} color="button">
              <Typography variant="button">닫기</Typography>
            </Button>
          </div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        <div />
      )}
    </ThemeProvider>
  );
};

export default withStyles(styles)(SearchView);
